import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';
import DropsSymbol from '../assets/icons/dropsSymbol';
import SmallLogo from '../assets/icons/smallLogo';
import Menu from './menu';

class Navbar extends React.Component {
  toggle = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  };

  renderBalance() {
    const { auth, account, t } = this.props;
    if (auth?.user && account?.balance >= 0) {
      return (
        <Link
          to="/transactions"
          className="text-color flex items-baseline"
        >
          <span className="text-color">{t('navbar.balance')}</span>
          <span className="tracking-tight flex items-baseline mx-1 text-color">
            <span className="font-medium text-lg">
              {account.balance}
            </span>
            <DropsSymbol color="color" />
          </span>
        </Link>
      );
    }
    return (
      <span className="text-color">
        &nbsp;{' '}
        {t('balance.placeholder')
          ? t('balance.placeholder')
          : Moment().format('MMMM Do')}
      </span>
    );
  }

  render() {
    return (
      <>
        <div className="relative z-99">
          <Menu />
          <div className="bg-secondary fixed border-b border-white border-opacity-25 h-16 w-full z-1">
            <div className="relative py-3 px-3">
              <nav className="z-99 bg-secondary flex pt-2 items-center">
                <div className="mr- ml-12">
                  <Link to="/" className="inline-block">
                    <SmallLogo />
                  </Link>
                  <span className="font-noto-emoji inline-block invisible">
                    &#10084;
                  </span>
                </div>
              </nav>
            </div>
          </div>
          <div className="pt-12" />
        </div>
      </>
    );
  }
}

Navbar.propTypes = {
  account: PropTypes.instanceOf(Object),
  auth: PropTypes.instanceOf(Object),
  t: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  account: {},
  auth: {},
};

export const mapStateToProps = (state) => ({
  account: state.account,
  auth: state.auth,
  layout: state.layout,
  theme: state.theme,
});

export default connect(mapStateToProps)(withTranslation()(Navbar));
