/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PageLoader from './components/PageLoader';
import { Viewport } from './helpers/Viewport';
import CheckTheme from './helpers/checkTheme';
import withClearCache from './helpers/ClearCache';
import Routes from './routes';

const App = () => {
  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex items-center">
          <PageLoader />
        </div>
      }
    >
      <Viewport>
        <CheckTheme />
        <Routes />
      </Viewport>
    </React.Suspense>
  );
};

export default withClearCache(App);
