import React from 'react';
import PropTypes from 'prop-types';

const DropsSymbol = ({ color, height }) => (
  <span className="inline-block">
    <svg
      height={height}
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.636 9.168L0.612 4.716L3.636 0.263999L6.66 4.716L3.636 9.168ZM3.636 7.956L5.844 4.716L3.636 1.476L1.416 4.716L3.636 7.956Z"
        className={`fill-${color}`}
      />
    </svg>
  </span>
);

DropsSymbol.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
};

DropsSymbol.defaultProps = {
  height: '12',
}

export default DropsSymbol;
