import { SET_ORGANIZER, SET_ACCOUNT} from '../action-types/organizer.types';
import Organizer from '../../models/organizer';
import { getAccount } from './account.actions';

export const getBalance = () => (dispatch, getState) => {
  const { organizer } = getState();
  if (organizer && organizer.accountAddress) {
    dispatch(getAccount(organizer.accountAddress))
      .then((data) => {
        return dispatch({
          type: SET_ACCOUNT,
          payload: data,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_ACCOUNT,
          payload: { balance: 0 },
        });
      });
  }
};

export const getOrganizer = () => (dispatch) => {
  Organizer.first().then((organizer) => {
    dispatch({
      type: SET_ORGANIZER,
      payload: organizer ? organizer.data : null,
    });
    dispatch(getBalance());
  });
};
