import {
  SET_TRANSACTIONS,
  SET_PENDING_TRANSACTIONS,
  ADD_PENDING_TRANSACTION,
  SET_TRANSACTIONS_LOADING,
} from '../action-types/transaction.types';
// eslint-disable-next-line import/no-cycle
import Transaction from '../../models/transaction';

export const setTransactions = (payload) => (dispatch) => {
  dispatch({ type: SET_TRANSACTIONS, payload });
};

export const addPendingTransaction = (payload) => (dispatch) => {
  dispatch({ type: ADD_PENDING_TRANSACTION, payload });
};

export const setPendingTransactions = (payload) => (dispatch) => {
  dispatch({ type: SET_PENDING_TRANSACTIONS, payload });
};

export const transactionLoaded = () => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_LOADING,
    payload: false,
  });
};

export const allTransactions = () => (dispatch, getState) => {
  const { auth } = getState();
  if (auth.beneficiary && auth.beneficiary.accountAddress) {
    (async () => {
      try {
        const sent = await Transaction.sent(
          auth.beneficiary.accountAddress,
        );
        const received = await Transaction.received(
          auth.beneficiary.accountAddress,
        );
        const transactions = [...sent.txs, ...received.txs];
        const result = transactions.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
        );
        dispatch(setTransactions(result));
        dispatch(transactionLoaded());
      } catch (error) {
        console.log(error);
      }
    })();
  }
};

export const checkPendingStatus = () => (dispatch, getState) => {
  const { transactions } = getState();
  const promises = [];
  if (transactions?.pending.length) {
    const counter = transactions.pending.length;
    const list = transactions.pending.map((transaction) => {
      const promise = Transaction.find(transaction.txhash);
      promises.push(promise);
      return transaction;
    });
    Promise.all(promises).then((values) => {
      values.map((value, index) => {
        if (value.error || value.height > 0) {
          list.splice(index, 1);
        }
        return value;
      });
      if (counter !== list.length) {
        dispatch(setPendingTransactions(list));
        dispatch(allTransactions());
      }
    });
  }
};
