import {
  SET_ACCOUNT,
  SET_ACCOUNT_DETAILS,
  SET_PRIVATE_KEY,
} from '../action-types/account.types';

import Cosmos from '../../lib/cosmos';

export const setAccount = (account) => (dispatch) => {
  return dispatch({
    type: SET_ACCOUNT,
    payload: account,
  });
};

export const getAccount = (address) => () => {
  return new Promise((resolve, reject) => {
    Cosmos.getAccounts(address)
      .then((data) => {
        const coins = data.result.value.coins.filter((coin) => {
          return coin.denom === process.env.REACT_APP_COSMOS_DENOM;
        });
        return resolve({
          balance: coins.length ? coins[0].amount : 0,
          ...data.result.value,
          address,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBalance = () => (dispatch, getState) => {
  const { auth } = getState();
  const { beneficiary } = auth;
  if (beneficiary && beneficiary.accountAddress) {
    dispatch(getAccount(beneficiary.accountAddress))
      .then((data) => {
        return dispatch({
          type: SET_ACCOUNT_DETAILS,
          payload: data,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_ACCOUNT_DETAILS,
          payload: { balance: 0 },
        });
      });
  }
};

export const setPrivateKey = (key) => (dispatch) => {
  return dispatch({
    type: SET_PRIVATE_KEY,
    payload: { ecpairPriv: key },
  });
};

export const setAddress = (address) => (dispatch) => {
  return dispatch({
    type: SET_ACCOUNT_DETAILS,
    payload: { address },
  });
};
