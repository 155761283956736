import {
  SET_ORGANIZER,
  SET_ACCOUNT,
} from '../action-types/organizer.types';
import { organizer as initialState } from '../initialState.json';

const organizerReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_ORGANIZER:
      return { ...state, ...payload };
    case SET_ACCOUNT:
      return { ...state, account: payload };
    default:
      return state;
  }
};

export default organizerReducer;
